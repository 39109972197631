<template>
    <b-card-code>
        
        <div class="mb-2">
        <b-row>
          <!-- <b-col md="4">
            <b-pagination
              v-if="allItems.length > 0"
              aria-controls="NewProductListTableId"
              v-model="pagination.currentPage"
              :total-rows="allItems.length"
              :per-page="pagination.perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              @change="onPageChange($event)"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col> -->
          <b-col md="10">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                class="d-inline-block mr-1"
                placeholder="Search by ID, Type, Designer, Seller Name..."
                v-model="searchTerm"
                type="text"
              />
            </div>
          </b-col>
          <!-- <b-col md="2">
            <b-form-select
              :options="PaginationList"
              v-model="PaginationCount"
              placeholder="Perpage"
              @input="pagechangeData()"
            />
          </b-col> -->
          <b-col md="2">
            <vue-excel-xlsx
                :data="allItems"
                :columns="excelColumns"
                :file-name="'Revivify - AllItems'"
                :file-type="'xlsx'"
                :sheet-name="'Revivify - AllItems'"
                class="btn btn-primary btn-md"
              >
                Download Excel
              </vue-excel-xlsx>
          </b-col>
        </b-row>
      </div>

        <!-- input search -->
        <!-- <div class="custom-search d-flex justify-content-end">
        <b-form-group>
            <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
            />
            </div>
        </b-form-group>
        </div> -->

        <!-- table -->
        <vue-good-table
            striped
            hover
            responsive
            :columns="tableColumns"
            :rows="allItems"
            :rtl="direction"
            :search-options="{
                enabled: true,
                externalQuery: searchTerm 
            }"
            :pagination-options="{
                enabled: true,
                perPage: pagination.perPage,
                setCurrentPage: pagination.currentPage
            }"
            @on-page-change="onPageChange($event)"
            styleClass="vgt-table bordered striped"
        >

        <template
            slot="table-row"
            slot-scope="props"
        >

            <div v-if="props.column.field === 'prod_id'">
                {{
                    parseInt((pagination.currentPage - 1) * pagination.perPage) + props.index + 1
                }}
            </div>

            <!-- Column: prod_id -->
            <div
                v-else-if="props.column.field === 'prod_auto_id'"
                class="text-nowrap"
            >
                <b-link
                    :to="{
                    name: `product-details`,
                    query: { id: props.row.prod_id },
                    }"
                    class="font-weight-bold"
                    @click="
                    $router.push({
                        name: `product-details`,
                        query: { id: props.row.prod_id },
                    })
                    "
                >
                    {{ props.row.prod_auto_id }}
                </b-link>
            </div>

            <!-- Column: prod_sub_category -->
            <div
                v-else-if="props.column.field === 'prod_sub_category'"
                class="text-nowrap"
            >
                <span class="text-nowrap">{{ props.row.prod_sub_category }}</span>
            </div>

            <!-- Column: prod_designer -->
            <div
                v-else-if="props.column.field === 'prod_designer'"
                class="text-nowrap"
            >
                <span class="text-nowrap">{{ props.row.prod_designer }}</span>
            </div>

            <!-- Column: creator.user_fname -->
            <div
                v-else-if="props.column.field === 'creator'"
                class="text-nowrap"
            >
                <span class="text-nowrap">{{ props.row.creator.user_fname +' '+  props.row.creator.user_lname }}</span>
            </div>

            <!-- Column: status -->
            <div
                v-else-if="props.column.field === 'prod_status_message'"
                class="text-nowrap"
            >
                <span class="text-nowrap">{{ props.row.prod_status_message }}</span>
            </div>

            <!-- Column: prod_created_at -->
            <div
                v-else-if="props.column.field === 'prod_created_at'"
                class="text-nowrap"
            >
                <span class="text-nowrap">{{ getHumanDate(props.row.prod_created_at) }}</span>
            </div>

            <!-- Column: action -->
            <div
                v-else-if="props.column.field === 'prod_updated_at'"
                class="text-nowrap"
            >
                <div class="text-nowrap">
                    <span>
                        <b-dropdown
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                        >
                            <template v-slot:button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="text-body align-middle mr-25"
                            />
                            </template>
                            <b-dropdown-item
                            v-if="props.row.prod_published"
                            @click="deactivate(props.row.prod_id, 0)"
                            >
                            <feather-icon icon="XCircleIcon" class="mr-50" />
                            <span>Deactivate</span>
                            </b-dropdown-item>

                            <b-dropdown-item
                            v-if="!props.row.prod_published"
                            @click="deactivate(props.row.prod_id, 1)"
                            >
                            <feather-icon icon="CheckCircleIcon" class="mr-50" />
                            <span>Activate</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </span>
                </div>
            </div>
            
        </template>

        <!-- pagination -->
        <!-- <template
            slot="pagination-bottom"
            slot-scope="props"
        >
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">
                    Showing 1 to
                    </span>
                    <b-form-select
                    v-model="pageLength"
                    :options="['10','20','10','30','40','50','100','200','500','1000']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap "> of {{ props.total }} entries </span>
                </div>
                <div>
                    <b-pagination
                        v-model="pagination.currentPage"
                        :value="pagination.currentPage"
                        :total-rows="props.total"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @change="onPageChange($event)"
                    >
                    <template #prev-text>
                        <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                        />
                    </template>
                    </b-pagination>
                </div>
            </div>
        </template> -->
        </vue-good-table>

        <template #code>
        {{ codeColumnSearch }}
        </template>

        <!-- <b-pagination
            class="mt-2"
            v-if="allItems.length > 0"
            aria-controls="NewProductListTableId"
            v-model="pagination.currentPage"
            :total-rows="allItems.length"
            :per-page="pagination.perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            @change="onPageChange($event)"
        >
            <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>

            <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
        </b-pagination> -->

    </b-card-code>
</template>
  
<script>

import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
    BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BLink,
  BTooltip,
  BCardText,
  BModal,
  BForm,
  BFormTextarea,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormSelectOption,
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { codeColumnSearch } from './code'


import Ripple from "vue-ripple-directive";
import feather from "feather-icons";
import vSelect from "vue-select";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import UserManagementServices from "@/apiServices/UserManagementServices";
import ProductServices from "@/apiServices/ProductServices";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import MasterServices from "@/apiServices/MasterServices";
import { refurbishStatus } from "./refurbishStatusCode";
import { sellStatus } from "./sellStatusCode";
import moment from "moment";


export default {

components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BLink,
    BTooltip,
    BCardText,
    BModal,
    BForm,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormSelectOption,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    feather,
    FeatherIcon,
    moment,
    vSelect,
},

beforeMount() {
    this.loadAllItems();
},
directives: {
    Ripple,
},

data() {
    return {
        pageLength: 10,
        dir: false,
        codeColumnSearch,
        rows: [],
        searchTerm: '',

        // Old Variables
        commissionPersentage: ["20%", "25%", "30%", "35%", "40%"],
        sellStatus: sellStatus,
        refurbishStatus: refurbishStatus,
        sellStatusObj: {},
        refurbishStatusObj: {},

        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],

        allItemsData: [],
        keyword: "",
        pagination: {
            currentPage: 1,
            perPage: 10,
            perPageOptions: [10, 20, 30],
        },
        PaginationList: [
            {
            value: "10",
            text: "10",
            },
            {
            value: "20",
            text: "20",
            },
            {
            value: "30",
            text: "30",
            },
            {
            value: "40",
            text: "40",
            },
            {
            value: "50",
            text: "50",
            },
            {
            value: "100",
            text: "100",
            },
            {
            value: "200",
            text: "200",
            },
            {
            value: "500",
            text: "500",
            },
        ],
        PaginationCount: "10",

        tableColumns: [
            { 
                key: "index", 
                field: "prod_id", 
                label: "Index",
                sortable: true
            },
            { 
                key: "prod_auto_id", 
                field: "prod_auto_id", 
                //field: this.prodAutoId,
                label: "Item ID",
                sortable: true,
                filterOptions: {
                    enabled: true,
                    placeholder: 'Search Item ID',
                },
            },
            {
                key: "prod_sub_category",
                field: "prod_sub_category",
                label: "Item Type",
                sortable: true,
                filterOptions: {
                    enabled: true,
                    placeholder: 'Search Item Type',
                },
            },
            {
                key: "prod_designer",
                field: "prod_designer",
                label: "Designer",
                sortable: true,
                filterOptions: {
                    enabled: true,
                    placeholder: 'Search Designer',
                },
            },
            {
                key: "seller_name",
                field: this.creatorName,
                label: "Seller Name",
                filterOptions: {
                    enabled: true,
                    placeholder: 'Search Name',
                },
            },
            {
                key: "prod_published",
                field: "prod_status_message",
                label: "Status",
                filterOptions: {
                    enabled: true,
                    placeholder: 'Search Status',
                },
            },
            {
                key: "prod_created_at",
                field: "prod_created_at",
                label: "Submmission Date",
                sortable: true,
                filterOptions: {
                    enabled: true,
                    placeholder: 'Search Date',
                },
            },
            {
                key: "prod_created_at",
                field: "prod_updated_at",
                label: "Action"
            }
        ],

        excelColumns: [
            {
            label: "Item ID",
            field: "prod_auto_id",
            },
            {
            label: "Designer",
            field: "prod_designer",
            },
            {
            label: "Seller Name",
            field: "creator",
            dataFormat: (val) => val.user_fname + " " + val.user_lname,
            },
            {
            label: "Status",
            field: "prod_status_message"
            },
            {
            label: "Submmission Date",
            field: "prod_created_at",
            dataFormat: (val) => moment(val).format("DD-MM-YYYY"),
            },
            {
            label: "Item Type Name",
            field: "prod_type",
            dataFormat: (val) => (val ? val.prod_sub_category : ""),
            },
            {
            label: "Seller Email",
            field: "creator.user_email",
            },
            {
            label: "Seller Phone",
            field: "creator.user_phone",
            },
            {
            label: "Payout Account Vendor",
            field: "creator",
            dataFormat: (val) => {
                if (val.user_stripe_id) {
                return "Stripe";
                }
                return "N/A";
            },
            },
            {
            label: "Payout Account Id",
            field: "creator",
            dataFormat: (val) => {
                if (val.user_stripe_id) {
                return val.user_stripe_id;
                }
                return "N/A";
            },
            },
            {
            label: "Item Listing Price",
            field: "prod_listing_price",
            },
            {
            label: "Seller Earning Price",
            field: "prod_earning_price",
            },
            {
            label: "Item Pickup Country",
            field: "prod_item_country",
            },
            {
            label: "Item Pickup City",
            field: "prod_item_city",
            },
        ],

        userData: null,
        tags_list: [],
        getComission: null,
        paginatorCurrentPage: 1,
        isLoading: true,

    }
},

computed: {
    allItems() {
      return this.searchTerm
        ? this.allItemsData.filter((item) => {

            if (
              item.creator !== null &&
              (item.creator["user_fname"] !== undefined ||
                item.creator["user_fname"] !== null)
            ) {

              if (item.prod_designer || item.prod_auto_id) {
                if (
                    item.prod_designer
                        .toString()
                        .toLowerCase()
                        .includes(this.searchTerm.toString().toLowerCase()) ||
                    item.creator.user_fname
                        .toString()
                        .toLowerCase()
                        .includes(this.searchTerm.toString().toLowerCase()) ||
                    item.prod_auto_id
                        .toString()
                        .includes(this.searchTerm.toString()) ||
                    item.prod_sub_category
                        .toString()
                        .toLowerCase()
                        .includes(this.searchTerm.toString().toLowerCase()) ||
                    item.prod_status_message
                        .toString()
                        .toLowerCase()
                        .includes(this.searchTerm.toString().toLowerCase())
                ) {
                  return true;
                } else {
                  return false;
                }

              }
            }
          })
        : this.allItemsData;
    },

    statusVariant() {
    const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
    }

    return status => statusColor[status]
    },
    direction() {
    if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
    }
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    this.dir = false
    return this.dir
    },
},

methods: {

    prodAutoId(rowObj){
        return rowObj.prod_auto_id;
    },

    creatorName(rowObj) {
        return rowObj.creator.user_fname + ' ' + rowObj.creator.user_lname;
    },

    onPageChange(currentPage, currentPerPage, total) {
        // currentPage - current page that pagination is at
        // currentPerPage - number of items per page
        // total - total number of items in the table
        
        if(typeof currentPage === "object"){
            this.pagination.currentPage = parseInt(currentPage.currentPage);
            if (this.pagination.currentPage != this.paginatorCurrentPage) {
                this.$router.push({
                    path: this.$route.fullPath,
                    query: { page: this.pagination.currentPage },
                });
            }
            this.paginatorCurrentPage = parseInt(currentPage.currentPage);
        }else{
            this.pagination.currentPage = parseInt(currentPage);
            if (this.pagination.currentPage != this.paginatorCurrentPage) {
                this.$router.push({
                    path: this.$route.fullPath,
                    query: { page: this.pagination.currentPage },
                });
            }
            this.paginatorCurrentPage = parseInt(currentPage);
        }

        console.log({currentPage, currentPerPage, total});
    },

    /* onPageChange(event) {
        console.log("this.event: ", event);
        if (event != this.paginatorCurrentPage) {
        this.$router.push({
            path: this.$route.fullPath,
            query: { page: event },
        });
        }
        this.paginatorCurrentPage = parseInt(event);
    }, */

    loadAllItems() {
        const { token, page } = this.$route.query;
        //this.paginatorCurrentPage = parseInt(page);
        console.log("ADMIN_TABLE_LOADED", token);

        if (token !== undefined && parseInt(token) > 0) {
        let user_id = token;
        this.getUserInfo(user_id);
        this.getTagsList();

        ProductServices.getAdminSellerItems(parseInt(token), "all")
            .then((response) => {
            this.allItemsData = response.data.data;
            console.log("AJAY", this.allItemsData);
            if (parseInt(page) > 1) {
                this.pagination.currentPage = parseInt(page);
                this.paginatorCurrentPage = parseInt(page);
                console.log(
                    "page1: ",
                    parseInt(page),
                    this.pagination.currentPage
                );
            }
            })
            .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            });
        } else {
        ProductServices.getAdminAllItems()
            .then((response) => {
            this.allItemsData = response.data.data;
            if (parseInt(page) > 1) {
                this.pagination.currentPage = parseInt(page);
                this.paginatorCurrentPage = parseInt(page);
                console.log(
                    "page2: ",
                    parseInt(page),
                    this.pagination.currentPage
                );
            }
            })
            .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            });
        }
    },

    getHumanDate(date) {
        // return (new Date(date)).toDateString();
        return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    showSellStatusMessage(code) {
        let obj = {};
        const x = this.sellStatus.filter((o, index) => {
        if (o.status_code === code) obj = this.sellStatus[index];
        });
        return obj;
    },

    showRefurbishStatusMessage(code) {
        let obj = {};
        const x = this.refurbishStatus.filter((o, index) => {
        if (o.status_code === code) obj = this.refurbishStatus[index];
        });
        return obj;
    },

    async getUserInfo(user_id) {
        if (!user_id) {
        return;
        }

        try {
        const response = await UserManagementServices.getSingleUser({
            user_id: user_id,
        });
        if (response.data.status) {
            this.userData = response.data.data;
            console.log("userInfo : ", this.userData);
        }
        } catch (err) {
        console.log("Error in getting user detials ", err);
        }
    },

    async getTagsList() {
        try {
        const response = await MasterServices.getTags({
            tag_type_name: "ComissionTagging",
        });
        if (response.data.status) {
            this.tags_list = response.data.data;
        }
        console.log("getTags: ", this.tags_list);
        } catch (err) {
        console.log("Error in getting all tags ", err);
        }
    },

    updateComission() {
        if (this.getComission != null) {
        let sendData = {
            comission: this.getComission.tag_description,
            uid: this.userData.user_id,
        };
        console.log(sendData);

        UserManagementServices.updateComission(sendData)
            .then((response) => {
            if (response.data.status) {
                this.getUserInfo(sendData.uid);
                this.Toast(response.data.message, "UserIcon", "success");
            } else {
                this.Toast(response.data.message, "AlertTriangleIcon", "error");
            }
            })
            .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            });
        }
    },

    Toast(message, icon, type) {
        this.$toast({
        component: ToastificationContent,
        props: {
            title: message,
            icon: icon,
            variant: type,
        },
        });
    },

    pagechangeData() {
        this.pagination.perPage = this.PaginationCount;
        this.loadAllItems();
    },

    deactivate(prod_id, type) {
      console.log(prod_id);

      const data = {
        prod_id: prod_id,
        type: type,
      };

      ProductServices.updateActiveInactive(data)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "BoxIcon",
              variant: "success",
            },
          });

          this.loadAllItems();
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
},

/* created() {
    this.$http.get('/good-table/basic')
    .then(res => { this.rows = res.data })
}, */

}
</script> 


<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.swal2-html-container {
  color: white;
}

.btn-secondary {
  border-color: #a96a37 !important;
  background-color: #a96a37 !important;
}
.user_details_card {
  padding: 1rem 2rem;
}
.seller_details_commission {
  align-items: center;
}
.seller_details_commission .seller_details_select {
  width: 38% !important;
}
</style>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>