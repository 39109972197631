var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{scopedSlots:_vm._u([{key:"code",fn:function(){return [_vm._v(" "+_vm._s(_vm.codeColumnSearch)+" ")]},proxy:true}])},[_c('div',{staticClass:"mb-2"},[_c('b-row',[_c('b-col',{attrs:{"md":"10"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Search by ID, Type, Designer, Seller Name...","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)]),_c('b-col',{attrs:{"md":"2"}},[_c('vue-excel-xlsx',{staticClass:"btn btn-primary btn-md",attrs:{"data":_vm.allItems,"columns":_vm.excelColumns,"file-name":'Revivify - AllItems',"file-type":'xlsx',"sheet-name":'Revivify - AllItems'}},[_vm._v(" Download Excel ")])],1)],1)],1),_c('vue-good-table',{attrs:{"striped":"","hover":"","responsive":"","columns":_vm.tableColumns,"rows":_vm.allItems,"rtl":_vm.direction,"search-options":{
            enabled: true,
            externalQuery: _vm.searchTerm 
        },"pagination-options":{
            enabled: true,
            perPage: _vm.pagination.perPage,
            setCurrentPage: _vm.pagination.currentPage
        },"styleClass":"vgt-table bordered striped"},on:{"on-page-change":function($event){return _vm.onPageChange($event)}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'prod_id')?_c('div',[_vm._v(" "+_vm._s(parseInt((_vm.pagination.currentPage - 1) * _vm.pagination.perPage) + props.index + 1)+" ")]):(props.column.field === 'prod_auto_id')?_c('div',{staticClass:"text-nowrap"},[_c('b-link',{staticClass:"font-weight-bold",attrs:{"to":{
                name: "product-details",
                query: { id: props.row.prod_id },
                }},on:{"click":function($event){return _vm.$router.push({
                    name: "product-details",
                    query: { id: props.row.prod_id },
                })}}},[_vm._v(" "+_vm._s(props.row.prod_auto_id)+" ")])],1):(props.column.field === 'prod_sub_category')?_c('div',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.prod_sub_category))])]):(props.column.field === 'prod_designer')?_c('div',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.prod_designer))])]):(props.column.field === 'creator')?_c('div',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.creator.user_fname +' '+ props.row.creator.user_lname))])]):(props.column.field === 'prod_status_message')?_c('div',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.prod_status_message))])]):(props.column.field === 'prod_created_at')?_c('div',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.getHumanDate(props.row.prod_created_at)))])]):(props.column.field === 'prod_updated_at')?_c('div',{staticClass:"text-nowrap"},[_c('div',{staticClass:"text-nowrap"},[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(props.row.prod_published)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deactivate(props.row.prod_id, 0)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"XCircleIcon"}}),_c('span',[_vm._v("Deactivate")])],1):_vm._e(),(!props.row.prod_published)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deactivate(props.row.prod_id, 1)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckCircleIcon"}}),_c('span',[_vm._v("Activate")])],1):_vm._e()],1)],1)])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }