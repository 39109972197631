import axiosInstance from './Api';

export default {

    /* Load All Dropdown for Sller in Item Form */
    async getUserTags(payload) {
        const { tag_type_name } = payload;

        let response = await axiosInstance().get(`/user-dd-master/tag/all/${tag_type_name}`);

        return response;
    },

    async getTagTypeDetails(payload) {
        const { tag_type_name } = payload;

        let response = await axiosInstance().get(`/master/tag_type/single/${tag_type_name}`);

        return response;
    },

    async getTags(payload) {
        const { tag_type_name } = payload;

        let response = await axiosInstance().get(`/master/tag/all/${tag_type_name}`);

        return response;
    },

    async getSingleTag(payload) {
        const { tag_id } = payload;

        let response = await axiosInstance().get(`/master/tag/single/${tag_id}`);

        return response;
    },

    async getSingleTagByName(payload) {
        const { tag_name } = payload;

        let response = await axiosInstance().get(`/master/tag/single/byname/${tag_name}`);

        return response;
    },

    async saveTag(payload) {

        let response = await axiosInstance().post(`/master/tag/save`, payload);

        return response;
    },

    async getAllServices(payload) {

        let response = await axiosInstance().get(`/master/service/all`, { params: payload });

        return response;
    },

    async getSingleService(payload) {

        const { service_id } = payload;

        let response = await axiosInstance().get(`/master/service/single/${service_id}`);

        return response;
    },

    async saveService(payload) {

        let response = await axiosInstance().post(`/master/service/save`, payload);

        return response;
    },

    async getAllCurrency() {

        let response = await axiosInstance().get(`/master/currency/all`);

        return response;
    },

    async getSingleCurrency(payload) {
        const { c_id } = payload;

        let response = await axiosInstance().get(`/master/currency/single/${c_id}`);

        return response;
    },

    async saveCurrency(payload) {

        let response = await axiosInstance().post(`/master/currency/save`, payload);

        return response;
    },
    
}