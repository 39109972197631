
export const codeColumnSearch = `
<template>
    <b-card-code title="Column Search Table">

        <!-- input search -->
        <div class="custom-search d-flex justify-content-end">
        <b-form-group>
            <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
            />
            </div>
        </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
            :columns="tableColumns"
            :rows="allItems"
            :rtl="direction"
            :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
            :pagination-options="{
                enabled: true,
                perPage:pageLength
            }"
        >
        <template
            slot="table-row"
            slot-scope="props"
        >

            <!-- Column: prod_id -->
            <div
                v-if="props.column.field === 'prod_id'"
                class="text-nowrap"
            >
                <b-link
                    :to="{
                    name: 'product-details',
                    query: { id: props.row.prod_id },
                    }"
                    class="font-weight-bold"
                    @click="
                    $router.push({
                        name: 'product-details',
                        query: { id: props.row.prod_id },
                    })
                    "
                >
                    {{ props.row.prod_auto_id }}
                </b-link>
            </div>

            <!-- Column: prod_sub_category -->
            <div
                v-else-if="props.column.field === 'prod_sub_category'"
                class="text-nowrap"
            >
                <span class="text-nowrap">{{ props.row.prod_sub_category }}</span>
            </div>

            <!-- Column: prod_designer -->
            <div
                v-else-if="props.column.field === 'prod_designer'"
                class="text-nowrap"
            >
                <span class="text-nowrap">{{ props.row.prod_designer }}</span>
            </div>

            <!-- Column: creator.user_fname -->
            <div
                v-else-if="props.column.field === 'creator'"
                class="text-nowrap"
            >
                <span class="text-nowrap">{{ props.row.creator.user_fname }}</span>
            </div>

            <!-- Column: status -->
            <div
                v-else-if="props.column.field === 'prod_published'"
                class="text-nowrap"
            >
                <span class="text-nowrap">{{ props.row.prod_published }} || TEST</span>
            </div>

            <!-- Column: prod_created_at -->
            <div
                v-else-if="props.column.field === 'prod_created_at'"
                class="text-nowrap"
            >
                <span class="text-nowrap">{{ getHumanDate(props.row.prod_created_at) }}</span>
            </div>
            
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
            <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                Showing 1 to
                </span>
                <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> of {{ props.total }} entries </span>
            </div>
            <div>
                <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
                >
                <template #prev-text>
                    <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                    />
                </template>
                <template #next-text>
                    <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                    />
                </template>
                </b-pagination>
            </div>
            </div>
        </template>
        </vue-good-table>

        <template #code>
        {{ codeColumnSearch }}
        </template>
    </b-card-code>
</template>
  
<script>

import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
    BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BLink,
  BTooltip,
  BCardText,
  BModal,
  BForm,
  BFormTextarea,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormSelectOption,
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { codeColumnSearch } from './code'


import Ripple from "vue-ripple-directive";
import feather from "feather-icons";
import vSelect from "vue-select";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import UserManagementServices from "@/apiServices/UserManagementServices";
import ProductServices from "@/apiServices/ProductServices";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import MasterServices from "@/apiServices/MasterServices";
import { refurbishStatus } from "./refurbishStatusCode";
import { sellStatus } from "./sellStatusCode";
import moment from "moment";


export default {

components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BLink,
    BTooltip,
    BCardText,
    BModal,
    BForm,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormSelectOption,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    feather,
    FeatherIcon,
    moment,
    vSelect,
},

beforeMount() {
    this.loadAllItems();
},
directives: {
    Ripple,
},

data() {
    return {
        pageLength: 10,
        dir: false,
        codeColumnSearch,
        columns: [
            {
            label: 'Name',
            field: 'fullName',
            filterOptions: {
                enabled: true,
                placeholder: 'Search Name',
            },
            },
            {
            label: 'Email',
            field: 'email',
            filterOptions: {
                enabled: true,
                placeholder: 'Search Email',
            },
            },
            {
            label: 'Date',
            field: 'startDate',
            filterOptions: {
                enabled: true,
                placeholder: 'Search Date',
            },
            },
            {
            label: 'Salary',
            field: 'salary',
            filterOptions: {
                enabled: true,
                placeholder: 'Search Salary',
            },
            },
            {
            label: 'Status',
            field: 'status',
            filterOptions: {
                enabled: true,
                placeholder: 'Search Status',
            },
            },
            {
            label: 'Action',
            field: 'action',
            },
        ],
        rows: [],
        searchTerm: '',

        // Old Variables
        commissionPersentage: ["20%", "25%", "30%", "35%", "40%"],
        sellStatus: sellStatus,
        refurbishStatus: refurbishStatus,
        sellStatusObj: {},
        refurbishStatusObj: {},

        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],

        allItemsData: [],
        keyword: "",
        pagination: {
            currentPage: 1,
            perPage: 10,
            perPageOptions: [10, 20, 30],
        },
        PaginationList: [
            {
            value: "10",
            text: "10",
            },
            {
            value: "20",
            text: "20",
            },
            {
            value: "30",
            text: "30",
            },
            {
            value: "40",
            text: "40",
            },
            {
            value: "50",
            text: "50",
            },
            {
            value: "100",
            text: "100",
            },
            {
            value: "200",
            text: "200",
            },
            {
            value: "500",
            text: "500",
            },
        ],
        PaginationCount: "10",

        tableColumns: [
            { 
                key: "prod_id", 
                field: "prod_id", 
                label: "Item ID",
                sortable: true,
                filterOptions: {
                    enabled: true,
                    placeholder: 'Search Item ID',
                },
            },
            {
                key: "prod_sub_category",
                field: "prod_sub_category",
                label: "Item Type",
                sortable: true,
                filterOptions: {
                    enabled: true,
                    placeholder: 'Search Item Type',
                },
            },
            {
                key: "prod_designer",
                field: "prod_designer",
                label: "Designer",
                sortable: true,
                filterOptions: {
                    enabled: true,
                    placeholder: 'Search Designer',
                },
            },
            {
                key: "seller_name",
                field: "creator",
                label: "Seller Name",
                filterOptions: {
                    enabled: true,
                    placeholder: 'Search Name',
                },
            },
            {
                key: "prod_published",
                field: "prod_published",
                label: "Status",
                sortable: true,
                filterOptions: {
                    enabled: true,
                    placeholder: 'Search Status',
                },
            },
            {
                key: "date_of_submition",
                field: "prod_created_at",
                label: "Submmission Date",
                sortable: true,
                filterOptions: {
                    enabled: true,
                    placeholder: 'Search Date',
                },
            }
        ],

        excelColumns: [
            {
            label: "Item ID",
            field: "prod_auto_id",
            },
            {
            label: "Designer",
            field: "prod_designer",
            },
            {
            label: "Seller Name",
            field: "creator",
            dataFormat: (val) => val.user_fname + " " + val.user_lname,
            },
            {
            label: "Status",
            field: "prod_status",
            dataFormat: (val) => {
                for (let i = 0; i < refurbishStatus.length; i++) {
                const status = refurbishStatus[i];
                if (status.status_code == val) {
                    return status.status_message;
                }
                }
                return "";
            },
            },
            {
            label: "Submmission Date",
            field: "prod_created_at",
            dataFormat: (val) => moment(val).format("DD-MM-YYYY"),
            },
            {
            label: "Item Type Name",
            field: "prod_type",
            dataFormat: (val) => (val ? val.prod_sub_category : ""),
            },
            {
            label: "Seller Email",
            field: "creator.user_email",
            },
            {
            label: "Seller Phone",
            field: "creator.user_phone",
            },
            {
            label: "Payout Account Vendor",
            field: "creator",
            dataFormat: (val) => {
                if (val.user_stripe_id) {
                return "Stripe";
                }
                return "N/A";
            },
            },
            {
            label: "Payout Account Id",
            field: "creator",
            dataFormat: (val) => {
                if (val.user_stripe_id) {
                return val.user_stripe_id;
                }
                return "N/A";
            },
            },
            {
            label: "Item Listing Price",
            field: "prod_listing_price",
            },
            {
            label: "Seller Earning Price",
            field: "prod_earning_price",
            },
            {
            label: "Item Pickup Country",
            field: "prod_item_country",
            },
            {
            label: "Item Pickup City",
            field: "prod_item_city",
            },
        ],

        userData: null,
        tags_list: [],
        getComission: null,
        paginatorCurrentPage: null,

    }
},

computed: {
    allItems() {
      return this.keyword
        ? this.allItemsData.filter((item) => {
            if (
              item.creator !== null &&
              (item.creator["user_fname"] !== undefined ||
                item.creator["user_fname"] !== null)
            ) {
              let fname = item.creator.user_fname
                ? item.creator.user_fname
                : "";
              console.log("Search Log => ", this.allItemsData);

              /* console.log("HAPPY: ", item.creator.user_fname);
            let fname = item.creator.user_fname ? item.creator.user_fname : "";
            console.log("SAD: ", item); */
              if (item.prod_designer || item.prod_auto_id) {
                if (
                  item.prod_designer
                    .toString()
                    .toLowerCase()
                    .includes(this.keyword.toString().toLowerCase()) ||
                  item.creator.user_fname
                    .toString()
                    .toLowerCase()
                    .includes(this.keyword.toString().toLowerCase()) ||
                  item.prod_auto_id
                    .toString()
                    .toLowerCase()
                    .includes(this.keyword.toString().toLowerCase()) ||
                  item.prod_sub_category
                    .toString()
                    .toLowerCase()
                    .includes(this.keyword.toString().toLowerCase())
                ) {
                  console.log("PS=>", item);
                  return true;
                } else {
                  console.log("PS false=>", item);
                  return false;
                }
              }
            }
          })
        : this.allItemsData;
    },

    statusVariant() {
    const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
    }

    return status => statusColor[status]
    },
    direction() {
    if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
    }
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    this.dir = false
    return this.dir
    },
},

methods: {
    onPageChange(event) {
        console.log("this.event: ", event);
        if (event != this.paginatorCurrentPage) {
        this.$router.push({
            path: this.$route.fullPath,
            query: { page: event },
        });
        }
        this.paginatorCurrentPage = parseInt(event);
    },

    loadAllItems() {
        const { token, page } = this.$route.query;
        this.paginatorCurrentPage = parseInt(page);
        console.log("ADMIN_TABLE_LOADED", token);

        if (token !== undefined && parseInt(token) > 0) {
        let user_id = token;
        this.getUserInfo(user_id);
        this.getTagsList();

        ProductServices.getAdminSellerItems(parseInt(token), "all")
            .then((response) => {
            this.allItemsData = response.data.data;
            console.log("AJAY", this.allItemsData);
            if (page > 1) {
                this.pagination.currentPage = parseInt(page);
                console.log(
                "page1: ",
                parseInt(page),
                this.pagination.currentPage
                );
            }
            })
            .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            });
        } else {
        ProductServices.getAdminAllItems()
            .then((response) => {
            this.allItemsData = response.data.data;
            if (page > 1) {
                this.pagination.currentPage = parseInt(page);
                console.log(
                "page2: ",
                parseInt(page),
                this.pagination.currentPage
                );
            }
            })
            .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            });
        }
    },

    getHumanDate(date) {
        // return (new Date(date)).toDateString();
        return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    showSellStatusMessage(code) {
        let obj = {};
        const x = this.sellStatus.filter((o, index) => {
        if (o.status_code === code) obj = this.sellStatus[index];
        });
        return obj;
    },

    showRefurbishStatusMessage(code) {
        let obj = {};
        const x = this.refurbishStatus.filter((o, index) => {
        if (o.status_code === code) obj = this.refurbishStatus[index];
        });
        return obj;
    },

    async getUserInfo(user_id) {
        if (!user_id) {
        return;
        }

        try {
        const response = await UserManagementServices.getSingleUser({
            user_id: user_id,
        });
        if (response.data.status) {
            this.userData = response.data.data;
            console.log("userInfo : ", this.userData);
        }
        } catch (err) {
        console.log("Error in getting user detials ", err);
        }
    },

    async getTagsList() {
        try {
        const response = await MasterServices.getTags({
            tag_type_name: "ComissionTagging",
        });
        if (response.data.status) {
            this.tags_list = response.data.data;
        }
        console.log("getTags: ", this.tags_list);
        } catch (err) {
        console.log("Error in getting all tags ", err);
        }
    },

    updateComission() {
        if (this.getComission != null) {
        let sendData = {
            comission: this.getComission.tag_description,
            uid: this.userData.user_id,
        };
        console.log(sendData);

        UserManagementServices.updateComission(sendData)
            .then((response) => {
            if (response.data.status) {
                this.getUserInfo(sendData.uid);
                this.Toast(response.data.message, "UserIcon", "success");
            } else {
                this.Toast(response.data.message, "AlertTriangleIcon", "error");
            }
            })
            .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            });
        }
    },

    Toast(message, icon, type) {
        this.$toast({
        component: ToastificationContent,
        props: {
            title: message,
            icon: icon,
            variant: type,
        },
        });
    },

    pagechangeData() {
        this.pagination.perPage = this.PaginationCount;
        this.loadAllItems();
    }
},

created() {
    this.$http.get('/good-table/basic')
    .then(res => { this.rows = res.data })
},
}
</script> 


`
